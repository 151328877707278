<template>
  <img :id="advanced?.id ?? ''" :style="{
    '--ratio': ratio,
  }" ref="myImg" :class="`image ${advanced?.class ?? ''}`" :src="finalSrc" :width="width" :height="height"
    :srcset="srcset.join(', ')" :sizes="sizes.join(', ')" :alt="alt" :title="title" :loading="lazy ? 'lazy' : 'eager'"
    :fetchpriority="priority" decoding="async" />
</template>

<script setup lang="ts">
import './image.css'
import __generateImageUrl from './utils/generateImageUrl.ts'
import type { TImage } from './image.type'
import { inject } from 'vue'
import { type TGridArea } from '~/components/gridArea/gridArea.type.ts'
import __projectConfig from '~/project.config.ts'

const props = withDefaults(defineProps<TImage>(), {
  lazy: true,
})

const grid = inject('grid', {}) as TGridArea
const srcset: string[] = []
const sizes: string[] = []
const finalSrc = __generateImageUrl(
  {
    ...props,
    imgix: {
      ...props.imgix,
      blur: 100,
    },
  },
  20,
  20,
)

for (let [i, size] of (<[]>__projectConfig.images.sizes).entries()) {
  srcset.push(`${__generateImageUrl(props, size)} ${size}w`)
}

const mobileMaxWidth = __projectConfig.breakpoints?.mobile?.[1] ?? 767
for (let [i, size] of (<[]>__projectConfig.images.sizes).entries()) {
  if (size <= mobileMaxWidth) {
    sizes.push(`(max-width: ${size}px) 100vw`)
  } else if (grid.width) {
    sizes.push(`(max-width: ${size}px) ${grid.width}vw`)
  } else {
    sizes.push(`(max-width: ${size}px) 100vw`)
  }
}

let ratio = 1
if (props.width && props.height) {
  ratio = props.width / props.height
}
</script>
